<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="(val) => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>
			<p class="text-capitalize">Application Bulk Upload Dialog</p>
		</template>
		<v-card-text>
			<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
				<FormMaker :schema="form_structure" :model="form_data"></FormMaker>
			</v-form>
		</v-card-text>
		<template v-slot:footer>
			<v-btn color="primary" class="text-capitalize" block @click="submit()">
				Upload
				{{
					form_data.application_type &&
					form_data.application_type.toString().replace(/(-)/g, " ")
				}}
				File
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { mapState } from "vuex";
	import { required } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: false, default: null },
			formTypeProp: { required: true, default: null },
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					user_id: null,
					application_type: this.currentUser?.id,
					application_data_providers_to_call: [],
					files: [],
				},
			};
		},
		computed: {
			agents_list() {
				let agents_list = this.$store.getters["assignableAgentLists"];
				return agents_list.map((item) => {
					return {
						text: `${item.name}/${item.email}`,
						value: item.id,
					};
				});
			},
			form_structure() {
				var structure = {
					application_data_providers_to_call: {
						type: "btn-toggle",
						name: "application_data_providers_to_call",
						label: "Data Providers To Call",
						...this.$helpers.formFieldsBasicLayout,
						options: this.$store.state.application.application_data_providers.map(
							(val) => val.value
						),
						class: "custom-style py-0 my-0 px-2 mb-2",
						mandatory: false,
						multiple: true,
						rules: [],
					},
					application_type: {
						type: "btn-toggle",
						name: "application_type",
						label: "Application Type",
						...this.$helpers.formFieldsBasicLayout,
						options: this.$store.state.application.application_types.map(
							(val) => val.value
						),
						class: "custom-style py-0 my-0 px-2 mb-2",
						mandatory: false,
						rules: [required],
					},
					user_id: {
						type: "select",
						name: "user_id",
						label: "Agent Assignment",
						...this.$helpers.formFieldsBasicLayout,
						multiple: false,
						returnObject: false,
						required: true,
						itemText: "text",
						itemValue: "value",
						items: this.agents_list,
						rules: [required("Field is required.")],
					},
					files: {
						type: "file",
						name: "file",
						label: "Upload Files",
						...this.$helpers.formFieldsBasicLayout,
						accept: ".xls,.xlsx",
						showSize: true,
						multiple: true,
						rules: [required()],
					},
				};

				return structure;
			},
		},
		watch: {},
		mounted() {
			this.form_data["user_id"] = this.currentUser?.id;
			this.form_data["application_type"] = this.formTypeProp;
			this.form_data["application_data_providers_to_call"] =
				this.$store.state.application.application_data_providers.map(
					(val) => val.value
				);
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			reloadApplications() {
				this.$emit("reloadApplications");
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				if (!confirm("Are you sure you want to upload this???")) return;

				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function (value) {
						// console.log(value);
						if (typeof value === "boolean") {
							return +value ? "1" : "0";
						}
						return value;
					},
				};

				let formData = window.jsonToFormData(this.form_data, options);

				// this.$notify({
				// 	group: "msg",
				// 	title: "Message",
				// 	text:
				// 		"Uploading file and getting processing applications. Please Wait...",
				// 	type: "success"
				// });

				return await this.post(
					`${this.baseUrl}/services/file-upload/create-application`,
					formData,
					true
				).then((res) => {
					if (res) {
						this.showMessageFromResponseDta(res);
					}
					if (res?.code == 200) {
						// this.$notify({
						// 	group: "msg",
						// 	title: "Message",
						// 	text: `Eligible Applications ${res.data.numberOfEligibleApplications}. Saved Applications ${res.data.numberOfSavedApplications}`,
						// 	type: "success"
						// });
						this.reloadApplications();
						this.closeDialog();
					}
					this.speedDial = false;
					return res;
				});
			},
		},
	};
</script>
